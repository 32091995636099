<svelte:head>
  <title>Sagar Dashboard Login</title>
  <script src="https://accounts.google.com/gsi/client"></script>
</svelte:head>

<script type="ts">
  import { onMount } from 'svelte';

  export const googleClientId: string = import.meta.env
    .VITE_GOOGLE_LOGIN_CLIENT_ID as string;

  interface Session {
    id: string;
    token: string;
  }

  interface SessionResponse {
    ok: boolean;
    session: Session;
  }

  function onMadeSession(resp: SessionResponse) {
    if (!resp.ok) {
      console.error("could not login", resp);
      return;
    }
    const session = resp.session;
    const cookiePostfix = "; path=/";
    document.cookie = `session-id=${session.id}${cookiePostfix}`;
    document.cookie = `session-token=${session.token}${cookiePostfix}`;
    window.location.href = "/";
  }

  function onSignIn(response: any) {
    const token = response.credential;
    const url = import.meta.env.VITE_API_SERVER + "/dashboard/login?token=";
    const req = { token: token };
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req),
    })
      .then((res) => res.json())
      .then(onMadeSession);
  }

  onMount(() => {
    displaySignInButton();
  });

  function displaySignInButton() {
      (window as any).google.accounts.id.initialize({
          client_id: googleClientId,
          callback: onSignIn,
      });
      (window as any).google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" }
      );
      (window as any).google.accounts.id.prompt();
  }
</script>

<div>
  <div id="buttonDiv"></div>
</div>
